@import "../../index.scss";
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem $paddingLeftRight;
  z-index: 100;
  // position: sticky;
  top: 0;
  transition: 0.5s ease;
  .navbar-items {
    display: inline;
    list-style-type: none;

    .navbar_unit {
      display: inline-block;

      a {
        display: inline-block;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 900;
        font-size: 1.5rem;
        color: $primaryColor;
        font-style: normal;
        margin-left: 2.8125rem;
      }

      .active {
        border-bottom: 1px solid #15ffb1;
      }
    }
  }
}

.navbar-shadow {
  // box-shadow: 0rem 0.125rem 1rem rgba(0, 0, 0, 0.15);
  // transition: 1s ease;
}
