@import "../../index.scss";
.footer {
  background: $gradiant;
  padding: 3.1vw;
  display: grid;
  grid-template-columns: 10% 15% 15% 12% 34% 7% 7% ;
  align-items: center;

  .footer-links {
    a {
      img {
        width: auto;
        height: 6rem;

      }
    }
  }

  img {
    max-width: 90%;
    position: relative;
  }

  .logo {
    display: grid;
    align-self: center;
    justify-items: center;
    padding: 0 2vw;
  }

  .logo-border-right {
    position: relative;
    display: grid;
    align-items: center;
  }

  .footer-coordinator {
    .header {
      font-weight: 800;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #ffffff;
      margin-bottom: 1em;
      position: relative;
      padding-bottom: 1em;
    }

    .header::after {
      content: "  ";
      width: 10%;
      border-bottom: 2px solid white;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    p {
      line-height: 1.5rem;
      font-size: 1rem;
      margin-bottom: 0.2em;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
