.group-details {
  .group-details-image {
    width: 100%;
    height: auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .group-details-logo {
      height: 0;
      background: #ffffff;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
      height: 17.5rem;
      width: 17.5rem;
      position: absolute;
      left: 16vw;
      bottom: -8rem;
      display: grid;
      align-items: center;
      justify-items: center;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        padding: 2vw;
        height: auto;
        width: auto;
      }
    }
  }

  .group-details-inner {
    padding: 0 7vw;

    .group-details-website {
      float: right;
      font-weight: bold;
      line-height: 1.4375rem;
      font-size: 1.125rem;

      color: #552e91;
    }

    .group-details-events {
      padding: 3rem 0;
      .group-details-events-unit {
        display: grid;
        grid-template-columns: 30% auto;
        grid-column-gap: 5%;

        .group-details-events-image {
          img {
            width: 100%;
          }
        }

        .group-details-events-name {
          font-weight: bold;
          line-height: 1.5rem;
          font-size: 1.5rem;
          padding-bottom: 1rem;
          color: #552e91;
        }

        .group-details-events-descritpion {
          font-weight: 300;
          line-height: 1.75rem;
          font-size: 1rem;
          text-align: justify;

          color: #000000;
        }

        .group-details-events-location {
          font-weight: 600;
          line-height: 1.75rem;
          font-size: 1rem;
          text-align: justify;
          margin-top: 1rem;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}

.group-details-contact-designation {
  font-style: italic;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: 1.125rem;

  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 1.4375rem;
}

.group-header {
  padding-top: 3rem;
}

.group-header-title {
  font-weight: 900;
  font-size: 36px;
  line-height: 45px;
  color: #4f4f4f;
  padding-bottom: 3rem;
}

.groups-header1 {
  display: flex;
  /* display: flex; */
  font-weight: 900;
  font-size: 3rem;
  line-height: 45px;
  color: #4f4f4f;
  /* -webkit-justify-content: space-evenly; */
  /* justify-content: space-evenly; */
  margin-bottom: 2rem;
  img {
    width: 7rem !important;
    margin-right: 2vw;
  }
}
.groups-info1 {
  font-size: 1.5rem;
  /* line-height: 30px; */
  padding-right: 5vw;
  color: #8c8c8c;
}
.groups-unit1 {
  display: flex;
  padding: 7% 7% 0 7%;
  position: relative;

  .group-image-holder1 {
    width: 91%;
    -webkit-clip-path: ellipse(50% 50% at 50% 50%);
    clip-path: ellipse(50% 50% at 50% 50%);
    margin: auto;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  img {
    border-radius: 0.125rem;
    width: 100%;
    height: 50%;
    /* padding-right: 1vw; */
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }
}
.title2 {
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 45px;

  /* Gray 2 */

  color: #4f4f4f;
}
table {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: left;
    padding: 5px 15px;
    font-weight: 900;
    font-size: 1rem;
    line-height: 23px;
    color: #4f4f4f;
  }
  td {
    text-align: left;
    padding: 5px 15px;
  }
  tr:nth-child(odd) {
    background-color: rgba(21, 255, 177, 0.18);
  }
}
.group-contact-unit {
  display: flex;
  padding: 8rem 0;
  img {
    border-radius: 50%;
    width: 71%;
  }
  .group-contact-detail {
    margin: auto 0;
  }
  .group-contact-detail-name {
    font-weight: 900;
    color: #4f4f4f;
    font-size: 1rem;
    line-height: 23px;
  }
}
