@import "../../../index.scss";
.AboutSrishti {
  padding-left: $paddingLeftRight;
  padding-right: 43.75rem;
  padding-top: 10rem;
  padding-bottom: 20.875rem;
  min-height: 100vh;

  background: url("../../../static/about.svg");
  background-size: 38.5625rem auto ;
  background-repeat: no-repeat;
  background-position: right;
}
