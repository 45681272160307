@import "../../../index.scss";
.vision,
.History {
  padding-left: $paddingLeftRight;
  padding-right: 43.75rem;
  padding-top: 10rem;
  padding-bottom: 16.875rem;
  min-height: 100vh;
}

.vision {
  background-image: url("../../../static/vision.svg"), $gradiant;
  background-position: bottom right;
  background-repeat: no-repeat;
  // background-size: 738px auto;
}
