@import "../../index.scss";
.achievements {
  padding: 7rem $paddingLeftRight;
  padding-right: 50rem;
  height: 100vh;
  background-image: url("../../static/achievement.png");
 
  background-position: center right ;
  background-size: 43.125rem auto;
  background-repeat: no-repeat;

  p{
    line-height: 2.5rem;
  }
}
