@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 7vw;
  z-index: 100;
  top: 0;
  transition: 0.5s ease; }
  .navbar .navbar-items {
    display: inline;
    list-style-type: none; }
    .navbar .navbar-items .navbar_unit {
      display: inline-block; }
      .navbar .navbar-items .navbar_unit a {
        display: inline-block;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 900;
        font-size: 1.5rem;
        color: #2F0072;
        font-style: normal;
        margin-left: 2.8125rem; }
      .navbar .navbar-items .navbar_unit .active {
        border-bottom: 1px solid #15ffb1; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.home-landing {
  padding: 0 50.875rem 20rem 7vw;
  margin-right: 7vw;
  margin-top: 9.375rem;
  background: url(/static/media/home.0341b7fd.JPG);
  background-repeat: no-repeat;
  background-size: 40% auto;
  background-position: right top;
  min-height: 90vh; }

.home-register {
  font-family: Lobster;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.8125rem;
  color: #2f0072;
  background: #f4f4f4;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.875rem;
  border: none;
  padding: 0.3125rem 2.625rem;
  margin-top: 3.75rem;
  cursor: pointer; }
  .home-register:focus {
    outline: none; }

.home-testimonial {
  padding: 11.25rem 14.5rem;
  display: grid;
  background: radial-gradient(123.36% 123.36% at 18.29% 15.92%, #72005F 0%, #648FFF 100%);
  grid-template-columns: 20% auto;
  grid-column-gap: 10.5rem; }
  .home-testimonial img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.625rem; }

.home-testimonial-text {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #f4f4f4; }

.home-stats {
  grid-column: 1 / 3;
  text-align: center; }
  .home-stats img {
    width: 82.3125rem;
    display: block;
    padding-top: 9.375rem;
    margin: auto; }

.groups-wrapper {
  padding: 5% 7vw; }
  .groups-wrapper h1 {
    font-weight: 900;
    font-size: 3rem; }
  .groups-wrapper h3 {
    font-size: 1.5rem;
    line-height: 30px;
    color: #8c8c8c;
    padding-bottom: 8rem; }

.groups {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5vw;
  -webkit-column-gap: 5vw;
          column-gap: 5vw;
  grid-row-gap: 4vw;
  row-gap: 4vw; }
  .groups .groups-unit {
    display: flex;
    position: relative; }
    .groups .groups-unit .group-image-holder {
      -webkit-clip-path: ellipse(50% 50% at 50% 50%);
              clip-path: ellipse(50% 50% at 50% 50%);
      margin: auto;
      box-shadow: inset 0rem 0rem 1.25rem rgba(0, 0, 0, 0.2); }
    .groups .groups-unit img {
      border-radius: 0.125rem;
      width: 100%;
      height: 100%;
      transition: 1s ease; }
    .groups .groups-unit .groups-header {
      display: flex;
      font-weight: 900;
      font-size: 2.25rem;
      line-height: 45px;
      color: #4f4f4f;
      justify-content: space-evenly; }
      .groups .groups-unit .groups-header img {
        width: 4rem !important; }
    .groups .groups-unit .groups-info {
      font-size: 1.5rem;
      padding: 0 11%;
      color: #8c8c8c; }
    .groups .groups-unit .groups-name::after {
      transition: 1s ease all;
      content: "";
      width: 0;
      border-bottom: 0.1875rem solid #552e91; }

.group-details .group-details-image {
  width: 100%;
  height: auto;
  position: relative; }
  .group-details .group-details-image img {
    width: 100%;
    height: 100%; }
  .group-details .group-details-image .group-details-logo {
    height: 0;
    background: #ffffff;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
    height: 17.5rem;
    width: 17.5rem;
    position: absolute;
    left: 16vw;
    bottom: -8rem;
    display: grid;
    align-items: center;
    justify-items: center;
    overflow: hidden; }
    .group-details .group-details-image .group-details-logo img {
      max-width: 100%;
      max-height: 100%;
      padding: 2vw;
      height: auto;
      width: auto; }

.group-details .group-details-inner {
  padding: 0 7vw; }
  .group-details .group-details-inner .group-details-website {
    float: right;
    font-weight: bold;
    line-height: 1.4375rem;
    font-size: 1.125rem;
    color: #552e91; }
  .group-details .group-details-inner .group-details-events {
    padding: 3rem 0; }
    .group-details .group-details-inner .group-details-events .group-details-events-unit {
      display: grid;
      grid-template-columns: 30% auto;
      grid-column-gap: 5%; }
      .group-details .group-details-inner .group-details-events .group-details-events-unit .group-details-events-image img {
        width: 100%; }
      .group-details .group-details-inner .group-details-events .group-details-events-unit .group-details-events-name {
        font-weight: bold;
        line-height: 1.5rem;
        font-size: 1.5rem;
        padding-bottom: 1rem;
        color: #552e91; }
      .group-details .group-details-inner .group-details-events .group-details-events-unit .group-details-events-descritpion {
        font-weight: 300;
        line-height: 1.75rem;
        font-size: 1rem;
        text-align: justify;
        color: #000000; }
      .group-details .group-details-inner .group-details-events .group-details-events-unit .group-details-events-location {
        font-weight: 600;
        line-height: 1.75rem;
        font-size: 1rem;
        text-align: justify;
        margin-top: 1rem;
        color: rgba(0, 0, 0, 0.3); }

.group-details-contact-designation {
  font-style: italic;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 1.4375rem; }

.group-header {
  padding-top: 3rem; }

.group-header-title {
  font-weight: 900;
  font-size: 36px;
  line-height: 45px;
  color: #4f4f4f;
  padding-bottom: 3rem; }

.groups-header1 {
  display: flex;
  /* display: flex; */
  font-weight: 900;
  font-size: 3rem;
  line-height: 45px;
  color: #4f4f4f;
  /* -webkit-justify-content: space-evenly; */
  /* justify-content: space-evenly; */
  margin-bottom: 2rem; }
  .groups-header1 img {
    width: 7rem !important;
    margin-right: 2vw; }

.groups-info1 {
  font-size: 1.5rem;
  /* line-height: 30px; */
  padding-right: 5vw;
  color: #8c8c8c; }

.groups-unit1 {
  display: flex;
  padding: 7% 7% 0 7%;
  position: relative; }
  .groups-unit1 .group-image-holder1 {
    width: 91%;
    -webkit-clip-path: ellipse(50% 50% at 50% 50%);
    clip-path: ellipse(50% 50% at 50% 50%);
    margin: auto;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.2); }
  .groups-unit1 img {
    border-radius: 0.125rem;
    width: 100%;
    height: 50%;
    /* padding-right: 1vw; */
    transition: 1s ease; }

.title2 {
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 45px;
  /* Gray 2 */
  color: #4f4f4f; }

table {
  border-collapse: collapse;
  width: 100%; }
  table th {
    text-align: left;
    padding: 5px 15px;
    font-weight: 900;
    font-size: 1rem;
    line-height: 23px;
    color: #4f4f4f; }
  table td {
    text-align: left;
    padding: 5px 15px; }
  table tr:nth-child(odd) {
    background-color: rgba(21, 255, 177, 0.18); }

.group-contact-unit {
  display: flex;
  padding: 8rem 0; }
  .group-contact-unit img {
    border-radius: 50%;
    width: 71%; }
  .group-contact-unit .group-contact-detail {
    margin: auto 0; }
  .group-contact-unit .group-contact-detail-name {
    font-weight: 900;
    color: #4f4f4f;
    font-size: 1rem;
    line-height: 23px; }

.about {
  display: grid; }
  .about .about-sidebar {
    height: 75vh;
    display: grid;
    width: 100%;
    align-items: center;
    justify-items: center; }
    .about .about-sidebar nav {
      position: fixed;
      top: 50vh;
      transform: rotate(-90deg);
      left: -8vw; }
      .about .about-sidebar nav a {
        display: inline;
        margin-right: 4rem;
        font-weight: 600;
        font-size: 1rem;
        text-decoration: none !important;
        color: #212121;
        padding: 0 !important; }
      .about .about-sidebar nav a:hover {
        text-decoration: none; }
      .about .about-sidebar nav .about-sidebar-active {
        font-weight: 800;
        line-height: 1.25rem;
        font-size: 1rem;
        color: #552E91; }

.AboutHeader {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 3rem;
  font-family: 'Playfair Display', serif;
  color: #212121; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.AboutSrishti {
  padding-left: 7vw;
  padding-right: 43.75rem;
  padding-top: 10rem;
  padding-bottom: 20.875rem;
  min-height: 100vh;
  background: url(/static/media/about.f3081ff1.svg);
  background-size: 38.5625rem auto;
  background-repeat: no-repeat;
  background-position: right; }

.Domain {
  margin: 5% 8vw; }
  .Domain .DomainArray {
    background: #F9F9F9;
    padding: 3.0625rem 3.5625rem;
    margin: 0 7vw;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-column-gap: 3vw;
    grid-row-gap: 1vw; }
    .Domain .DomainArray div img {
      height: 2em;
      width: 2em;
      vertical-align: center;
      display: none; }
    .Domain .DomainArray div span, .Domain .DomainArray div li {
      font-weight: 300;
      line-height: 1.75rem;
      font-size: 1rem;
      color: #000000;
      margin-left: 1rem; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.vision,
.History {
  padding-left: 7vw;
  padding-right: 43.75rem;
  padding-top: 10rem;
  padding-bottom: 16.875rem;
  min-height: 100vh; }

.vision {
  background-image: url(/static/media/vision.62494a78.svg), radial-gradient(123.36% 123.36% at 18.29% 15.92%, #72005F 0%, #648FFF 100%);
  background-position: bottom right;
  background-repeat: no-repeat; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.achievements {
  padding: 7rem 7vw;
  padding-right: 50rem;
  height: 100vh;
  background-image: url(/static/media/achievement.3062e5f0.png);
  background-position: center right;
  background-size: 43.125rem auto;
  background-repeat: no-repeat; }
  .achievements p {
    line-height: 2.5rem; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body,
div#root,
div.App {
  width: 100%; }

ul {
  list-style-type: none; }

a {
  text-decoration: none;
  color: inherit; }

* {
  font-family: "Muli", sans-serif; }

html {
  font-size: 0.8333333vw; }

.textColorBlack {
  color: #8C8C8C; }

.textColorWhite {
  color: #F4F4F4; }

html {
  background-color: #F4F4F4; }

.footer {
  background: radial-gradient(123.36% 123.36% at 18.29% 15.92%, #72005F 0%, #648FFF 100%);
  padding: 3.1vw;
  display: grid;
  grid-template-columns: 10% 15% 15% 12% 34% 7% 7%;
  align-items: center; }
  .footer .footer-links a img {
    width: auto;
    height: 6rem; }
  .footer img {
    max-width: 90%;
    position: relative; }
  .footer .logo {
    display: grid;
    align-self: center;
    justify-items: center;
    padding: 0 2vw; }
  .footer .logo-border-right {
    position: relative;
    display: grid;
    align-items: center; }
  .footer .footer-coordinator .header {
    font-weight: 800;
    line-height: 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 1em;
    position: relative;
    padding-bottom: 1em; }
  .footer .footer-coordinator .header::after {
    content: "  ";
    width: 10%;
    border-bottom: 2px solid white;
    position: absolute;
    left: 0;
    bottom: 0; }
  .footer .footer-coordinator p {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 0.2em;
    color: rgba(255, 255, 255, 0.8); }

.team {
  padding: 7.5rem 15rem; }
  .team .team_type {
    font-weight: 300;
    line-height: 1.75rem;
    font-size: 1.5rem;
    color: #000000; }
  .team .team_array {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 3.75rem;
    grid-row-gap: 3.75rem;
    padding: 2rem 0;
    transition: 0.2s ease all; }
    .team .team_array .team_unit img {
      display: block;
      width: 100%;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      transition: 0.2s ease all;
      height: 100%; }
    .team .team_array .team_unit div {
      height: 22vw; }
    .team .team_array .team_unit .name {
      line-height: 1.25rem;
      font-size: 1.25rem;
      text-align: center;
      padding-top: 1em;
      color: #000000;
      margin-bottom: 0.5rem; }
    .team .team_array .team_unit .des {
      font-weight: bold;
      line-height: 1.25rem;
      font-size: 0.875rem;
      text-align: center;
      padding-bottom: 0.5em;
      margin-bottom: 0;
      color: #979797; }
    .team .team_array .team_unit i {
      font-size: 2rem;
      padding-right: 0.5em;
      display: inline-block;
      padding-bottom: 1em;
      color: #000;
      color: initial;
      transition: 0.2s ease all;
      opacity: 0.5; }
    .team .team_array .team_unit:hover {
      background: #ffffff;
      transition: 0.2s ease all;
      box-shadow: 0px 3px 40px rgba(100, 103, 105, 0.12); }
      .team .team_array .team_unit:hover img {
        -webkit-filter: none;
                filter: none;
        transition: 0.2s ease all; }
      .team .team_array .team_unit:hover .fa-facebook {
        color: #3b5998;
        transition: 0.2s ease all;
        opacity: 1; }
      .team .team_array .team_unit:hover .fa-linkedin {
        color: #0077B5;
        transition: 0.2s ease all;
        opacity: 1; }

