.groups-wrapper {
  padding: 5% 7vw;
  h1 {
    font-weight: 900;
    font-size: 3rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 30px;
    color: #8c8c8c;
    padding-bottom: 8rem;
  }
}

.groups {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5vw;
  row-gap: 4vw;

  .groups-unit {
    display: flex;
    position: relative;

    .group-image-holder {
      clip-path: ellipse(50% 50% at 50% 50%);
      margin: auto;
      box-shadow: inset 0rem 0rem 1.25rem rgba(0, 0, 0, 0.2);
    }

    img {
      border-radius: 0.125rem;
      width: 100%;
      height: 100%;
      transition: 1s ease;
    }

    .groups-header {
      display: flex;
      font-weight: 900;
      font-size: 2.25rem;
      line-height: 45px;
      color: #4f4f4f;
      justify-content: space-evenly;
      img {
        width: 4rem !important;
      }
    }
    .groups-info {
      font-size: 1.5rem;
      padding: 0 11%;
      color: #8c8c8c;
    }
    .groups-name::after {
      transition: 1s ease all;
      content: "";
      width: 0;

      border-bottom: 0.1875rem solid #552e91;
    }
  }
}
