$primaryColor : #2F0072;

$headingColor : #000000;
$textColorBlack : #8C8C8C;
$textColorWhite : #F4F4F4;

.textColorBlack{
    color: $textColorBlack;
}

.textColorWhite{
    color: $textColorWhite;
}