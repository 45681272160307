* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div#root,
div.App {
  width: 100%;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}
