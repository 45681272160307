@import "../../index.scss";

.home-landing {
  padding: 0 50.875rem 20rem $paddingLeftRight;
  margin-right: $paddingLeftRight;
  margin-top: 9.375rem;
  background: url("../../static/home.JPG");       
  background-repeat: no-repeat;
  background-size: 40% auto;
  background-position: right top;
  min-height: 90vh;
}

.home-register {
  font-family: Lobster;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.8125rem;
  color: #2f0072;

  background: #f4f4f4;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.875rem;
  border: none;
  padding: 0.3125rem 2.625rem;
  margin-top: 3.75rem;

  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.home-testimonial {
  padding: 11.25rem 14.5rem;
  display: grid;
  background: $gradiant;
  grid-template-columns: 20% auto;
  grid-column-gap: 10.5rem;

  img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.625rem;
  }
}

.home-testimonial-text {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #f4f4f4;
  // margin-top: 2rem;
}

.home-stats{
  grid-column: 1 / 3;
  text-align: center;

  img{
    width: 82.3125rem;
    display: block;
    padding-top: 9.375rem;
    margin: auto;
  }
}