.Domain {
    margin: 5% 8vw;

    .DomainArray {
        background: #F9F9F9;
        padding: 3.0625rem 3.5625rem;
        margin: 0 7vw;
        display: grid;
        grid-template-columns: repeat(3, 30%);
        grid-column-gap: 3vw;
        grid-row-gap: 1vw;

        div {
            img {
                height: 2em;
                width: 2em;
                vertical-align: center;
                display: none;
            }

            span,li {
                font-weight: 300;
                line-height: 1.75rem;
                font-size: 1rem;

                color: #000000;

                margin-left: 1rem;
            }

            
        }


    }
}