.team {
    padding: 7.5rem 15rem;

    .team_type {
        font-weight: 300;
        line-height: 1.75rem;
        font-size: 1.5rem;
        color: #000000;
    }

    .team_array {
        display: grid;
        grid-template-columns: 30% 30% 30% ;
        grid-column-gap: 3.75rem;
        grid-row-gap: 3.75rem;

        padding: 2rem 0;
        transition: 0.2s ease all;

        .team_unit {
            img {
                display: block;
                width: 100%;
                filter: grayscale(100%);
                transition: 0.2s ease all;
                height: 100%;

            }

            div{
                height:22vw;
                
            }

            .name {
                line-height: 1.25rem;
                font-size: 1.25rem;
                text-align: center;
                padding-top: 1em;
                color: #000000;
                margin-bottom: 0.5rem;
            }

            .des {
                font-weight: bold;
                line-height: 1.25rem;
                font-size: 0.875rem;
                text-align: center;
                padding-bottom: 0.5em;
                margin-bottom: 0;
                color: #979797;

            }

            i{
                font-size: 2rem;
                padding-right: 0.5em;
                display: inline-block;
                padding-bottom: 1em;
                color: initial;
                transition: 0.2s ease all;
                opacity: 0.5;
                
            }
           
        }

        .team_unit:hover {
            img {
                filter: none;
                transition: 0.2s ease all;
            }
            .fa-facebook{
                color: #3b5998;
                transition: 0.2s ease all;
                opacity: 1;
            }
            .fa-linkedin{
                color: #0077B5;
                transition: 0.2s ease all;
                opacity: 1;
            }
            background: #ffffff;
            transition: 0.2s ease all;
            box-shadow: 0px 3px 40px rgba(100, 103, 105, 0.12);
        }
    }
}