.about {
    display: grid;
 

    .about-sidebar {
        height: 75vh;
        display: grid;
        width: 100%;
        align-items: center;
        justify-items: center;
        nav {
 
          position: fixed;
          top:50vh;
          transform: rotate(-90deg);
          left:-8vw;
         
       
            a {
             
                display: inline;
                margin-right:4rem;
                font-weight: 600;
                font-size: 1rem;
                text-decoration: none !important;
                color: #212121;
                padding: 0 !important;
            }

            a:hover {
                text-decoration: none;

            }

            .about-sidebar-active {
                font-weight: 800;
                line-height: 1.25rem;
                font-size: 1rem;

                color: #552E91;
            }

        }

    }
}


.AboutHeader {
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 3rem;
    font-family: 'Playfair Display', serif;
    color: #212121;
}